import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import * as authenticationActions from './authentication.actions';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { BrowserStorage } from 'src/app/core/models/browser-storage.enum';

@Injectable()
export class AuthenticationEffects {
  caliperx = (window as any).caliperx;

  checkAuthenticationState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationActions.checkAuthenticationState),
      map(() => {
        if (this.caliperx.accountInfo.authenticated) {
          return authenticationActions.setAuthenticatedState();
        } else {
          return authenticationActions.setUnauthenticatedState();
        }
      })
    )
  );

  clearSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authenticationActions.clearSession),
        map(action =>
          this.authenticationService.endSession(action.logoutAction).subscribe()
        ),
        tap(() => {
          /** Local storage */
          localStorage.clear();
          /** Session storage */
          sessionStorage.removeItem(BrowserStorage.STATE);
          sessionStorage.removeItem(BrowserStorage.STATE_NONCE);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationService
  ) {}
}
