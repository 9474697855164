import { Action, createReducer, on } from '@ngrx/store';

import * as authenticationActions from './authentication.actions';
import { User } from 'src/app/core/models/user.model';
import { StepUp } from 'src/app/core/models/step-up.model';
import { StoreStates } from 'src/app/core/models/store-states.enum';

export interface AuthenticationState {
  loaded: StoreStates;
  authenticated: boolean;
  user: User;
  stepUpData: StepUp;
}

export const initialState: AuthenticationState = {
  loaded: StoreStates.UNKNOWN,
  authenticated: undefined,
  user: {
    eid: null,
    email: null,
    firstName: null,
    uuid: null,
    username: null,
    failedLoginCount: null,
    lastName: null,
    id: null,
    role: null,
    lastPassUpdate: null,
    jobTitle: null,
  },
  stepUpData: null,
};

const authenticationReducer = createReducer(
  initialState,
  on(authenticationActions.setAuthenticatedState, state => ({
    ...state,
    loaded: StoreStates.SUCCESS,
    authenticated: true,
  })),
  on(authenticationActions.setUnauthenticatedState, state => ({
    ...initialState,
    loaded: StoreStates.SUCCESS,
  })),
  on(authenticationActions.clearSession, state => ({
    ...initialState,
    loaded: StoreStates.SUCCESS,
  })),
  on(authenticationActions.getUserDetails, state => ({
    ...state,
    user: window.caliperx.accountInfo.profile,
  })),
  on(
    authenticationActions.updateLastPassUpdate,
    (state, { lastPassUpdate }) => ({
      ...state,
      user: {
        ...state.user,
        lastPassUpdate,
      },
    })
  ),
  on(authenticationActions.stepUpAuthentication, (state, { stepUpData }) => ({
    ...state,
    stepUpData,
  }))
);

export function reducer(
  state: AuthenticationState | undefined,
  action: Action
) {
  return authenticationReducer(state, action);
}
