import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';
import {
  AuthEngineEndpoints,
  AuthenticationEndpoints as endpoints,
} from '../../shared/services/service-endpoints';
import { FeatureToggleService } from './feature-toggle.service';

@Injectable()
export class SignUpService {
  constructor(
    private httpClient: HttpClient,
    private analyticsService: AnalyticsService,
    private featureToggleService: FeatureToggleService
  ) { }

  createAccount(signUpData: any, token?: string): Observable<any> {
    if (token) {
      signUpData.token = token;
      return this.featureToggleService.getFeatureToggle('auth-engine').pipe(
        switchMap(
          (enabled): Observable<any> => {
            const url = enabled
              ? AuthEngineEndpoints.CREATE_ACCOUNT_WITH_TOKEN
              : endpoints.CREATE_ACCOUNT_WITH_TOKEN;
            return this.httpClient.post(url, signUpData).pipe(
              map(response => response as any),
              catchError(error => this.handleError(error))
            );
          }
        )
      );
    } else {
      return this.featureToggleService.getFeatureToggle('auth-engine').pipe(
        switchMap(
          (enabled): Observable<any> => {
            const url = enabled
              ? AuthEngineEndpoints.CREATE_ACCOUNT
              : endpoints.CREATE_ACCOUNT;
            return this.httpClient.post(url, signUpData).pipe(
              map(response => response as any),
              catchError(error => this.handleError(error))
            );
          }
        )
      );
    }
  }

  forgotPassword(email: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    headers.append('Accept', '*/*');
    return this.featureToggleService.getFeatureToggle('auth-engine').pipe(
      switchMap(
        (enabled): Observable<any> => {
          const url = enabled
            ? AuthEngineEndpoints.FORGOT_PASSWORD
            : endpoints.FORGOT_PASSWORD;
          return this.httpClient
            .post(url, { email: email }, { headers: headers })
            .pipe(
              map(response => response as any),
              catchError(error => this.handleError(error))
            );
        }
      )
    );
  }

  resetPassword(resetData: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    headers.append('Accept', '*/*');
    const options = { headers: headers };

    const isEmailExists = resetData.email !== '';
    const payloadData = {
      ...(isEmailExists && { email: resetData.email }),
      password: resetData.password,
      token: resetData.token,
    };
    return this.featureToggleService.getFeatureToggle('auth-engine').pipe(
      switchMap(
        (enabled): Observable<any> => {
          const resetPWUrl = enabled
            ? AuthEngineEndpoints.RESET_PASSWORD
            : endpoints.RESET_PASSWORD;
          return this.httpClient.post(resetPWUrl, payloadData, options).pipe(
            map(resp => resp as any),
            catchError(error => {
              return throwError(error);
            })
          );
        }
      )
    );
  }

  changePassword(data: any): any {
    const headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    headers.append('Accept', '*/*');
    let payload = { ...data };
    if (payload.verifyPassword) delete payload.verifyPassword;
    const options = { headers: headers };
    return this.featureToggleService.getFeatureToggle('auth-engine').pipe(
      switchMap(
        (enabled): Observable<any> => {
          const resetPWUrl = enabled
            ? AuthEngineEndpoints.UPDATE_PASSWORD
            : endpoints.RESET_PASSWORD;
          return this.httpClient.put(resetPWUrl, payload, options).pipe(
            map(resp => resp as any),
            catchError(error => this.handleError(error))
          );
        }
      )
    );
  }

  private handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `${error.error.developerText}`;
    }
    this.analyticsService.pubSubEvent({
      name: `application_submit_failure:${this.constructor.name}:error-${errorMessage}`,
    });

    return throwError({ status: 'ERROR', message: errorMessage });
  }
}
