import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AnalyticsService } from '../../shared/services/analytics.service';
import {
  AuthEngineEndpoints,
  AuthenticationEndpoints as endpoints,
} from '../../shared/services/service-endpoints';
import { FeatureToggleService } from 'src/app/shared/services/feature-toggle.service';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  constructor(
    private httpClient: HttpClient,
    private analyticsService: AnalyticsService,
    private featureToggleService: FeatureToggleService
  ) {}

  private handleError(error: any, methodName?: string) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.analyticsService.pubSubEvent({
      name: `application_submit_failure:${this.constructor.name}:error-${methodName}-user-portrait:${errorMessage}`,
    });
    return of(null);
  }

  /** Attempt to upload new user portrait */
  public saveUserPortrait(image: string): any {
    /**
     * For some reason, the request isn't being intercepted.  We have to add the token now.
     */
    const headers: HttpHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );
    const options = { headers: headers };
    const data = { image: image };
    return this.featureToggleService.getFeatureToggle('auth-engine').pipe(
      switchMap(
        (enabled): Observable<any> => {
          const url = enabled
            ? AuthEngineEndpoints.USER_PROFILE_PICTURE
            : endpoints.USER_PROFILE_PICTURE;
          return this.httpClient.post(url, data, options).pipe(
            map(resp => resp as any),
            catchError(error => this.handleError(error, 'saveUserPortrait'))
          );
        }
      )
    );
  }

  /** Return base64 encoded user portrait, null if failure/not available */
  public getUserPortrait(): any {
    return this.featureToggleService.getFeatureToggle('auth-engine').pipe(
      switchMap(
        (enabled): Observable<any> => {
          const url = enabled
            ? AuthEngineEndpoints.USER_PROFILE_PICTURE
            : endpoints.USER_PROFILE_PICTURE;
          return this.httpClient.get(url).pipe(
            map(resp => (resp as any).image),
            catchError(error => this.handleError(error, 'getUserPortrait'))
          );
        }
      )
    );
  }
}
